import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../lib/utils";

const buttonVariants = cva(
  "inline-flex gap-1.5 items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-gray-300 border-transparent border",
  {
    variants: {
      variant: {
        default:
          "bg-primary shadow-sm text-gray-50 hover:bg-primary/90 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 ",
        destructive:
          "bg-red-500 text-gray-50 shadow-sm hover:bg-red-500/90 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900/90",
        outline:
          "border shadow-sm border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50",
        secondary:
          "bg-gray-100 shadow-sm text-gray-900 hover:bg-gray-100/80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800/80",
        ghost:
          "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50",
        link: "text-gray-900 underline-offset-2 underline dark:text-gray-50",
        text: "text-gray-700 dark:text-gray-50 hover:text-gray-700/50",
      },
      size: {
        default: "h-9 px-3 py-1",
        sm: "h-8 rounded-md px-3 text-sm",
        lg: "h-10 rounded-md px-8",
        icon: "h-8 w-8 rounded-md",
        "icon-sm": "h-7 w-7 rounded",
        fit: "h-fit w-fit bg-transparent shadow-none hover:bg-transparent hover:opacity-70",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  icon?: string;
  iconPosition?: "left" | "right";
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading = false,
      icon,
      iconPosition = "right",
      loadingText,
      type = "button",
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        disabled={isLoading}
        ref={ref}
        type={type}
        {...props}
      >
        {isLoading ? (
          <span className="flex flex-row items-center justify-between gap-1.5">
            <span className="iconify size-4 shrink-0 animate-spin material-symbols--progress-activity" />
            {loadingText ? loadingText : props.children}
          </span>
        ) : (
          <>
            {icon && iconPosition == "left" ? (
              <span className={`${icon} iconify size-4`} />
            ) : null}
            {props.children}
            {icon && iconPosition == "right" ? (
              <span className={`${icon} iconify size-4`} />
            ) : null}
          </>
        )}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
